import { createApp } from "./vue.esm-browser.js";
import { format, fromUnixTime, getDayOfYear } from "date-fns";

const app = createApp({
	setup() {},
	mounted: function () {
		this.fetchEvents();
		this.fetchSpeakers();
	},
	updated: function () {
		new Glide(".glide", {
			type: "carousel",
			autoplay: 3000,
			perView: 6,
		}).mount();
	},

	data() {
		const data = {
			committees: [
				{
					name: "Program Co-Chairs",
					chairs: [
						{
							photo: "leibowski.jpg",
							name: "David P. Leibowitz",
							office: "Law Offices of David P. Leibowitz; Chicago",
						},
						{
							photo: "shaw.jpg",
							name: "Summer Shaw",
							office: "Shaw & Hanover, PC; Palm Desert, CA.",
						},
					],
				},
				{
					name: "Planning Committee",
					chairs: [
						{
							name: "Anerio V. Altman",
							office: "Lake Forest Bankruptcy; Laguna Hills, CA.",
						},
						{
							name: "Karlene A. Archer",
							office: "Legal Aid Society of Mid-New York; Syracuse, N.Y.",
						},
						{
							name: "Stephen E. Berken",
							office: "Berken Cloyes, PC; Denver",
						},
						{
							name: "John R. Bollinger",
							office: "Boleman Law Firm, P.C.; Hampton, Va.",
						},
						{
							name: "Bradford W. Botes",
							office: "Bond & Botes Law Offices; Birmingham, Ala.",
						},
						{
							name: "Joshua Cohen",
							office: "Cohen Consumer Law, PLC; St. Albans, Vt.",
						},
						{
							name: "J. Scott Bovitz",
							office: "Bovitz & Spitzer; Los Angeles",
						},
						{
							name: "Rudy J. Cerone",
							office: "McGlinchey Stafford PLLC; New Orleans",
						},
						{
							name: "Hon. Bess P. Creswell",
							office: "U.S. Bankruptcy Court (N.D. Ala.); Montgomery",
						},
						{
							name: "Nathan E. Curtis",
							office: "Geraci Law, LLC; Chicago",
						},
						{
							name: "Rachel L. Foley",
							office: "Foley Law, PC; Independence, Mo.",
						},
						{
							name: "Jeffrey S. Fraser",
							office: "Albertelli Law; Tampa, FL.",
						},
						{
							name: "Rebecca R. Garcia",
							office: "Office of the Chapter 13 Trustee; Oshkosh, Wis.",
						},
						{
							name: "Heather M. Giannino",
							office: "Heavner, Beyers & Mihlar, LLC; Decatur Ill.",
						},
						{
							name: "Hon. Mary P. Gorman",
							office: "U.S. Bankruptcy Court (C.D. Ill.); Springfield",
						},
						{
							name: "Zvi Guttman",
							office: "The Law Offices of Zvi Guttman, PA; Baltimore",
						},
						{
							name: "Hon. Bruce A. Harwood",
							office: "U.S. Bankruptcy Court (D. N.H.); Concord",
						},
						{
							name: "John M. Hauber",
							office: "Office of the Chapter 13 Standing Trustee; Indianapolis",
						},
						{
							name: "Hon. Jennifer H. Henderson",
							office: "U.S. Bankruptcy Court (N.D. Ala.); Tuscaloosa",
						},
						{
							name: "Keith J. Larson",
							office: "Seiller Waterman, LLC; Louisville, Ky.",
						},
						{
							name: "Donald R. Lassman",
							office: "Law Office of Donald R. Lassman; Needham, Mass.",
						},
						{
							name: "Jon J. Lieberman",
							office: "Sottile & Barile LLC; Loveland, Ohio",
						},
						{
							name: "Jennifer M. Meyerowitz",
							office: "Stretto; Atlanta",
						},
						{
							name: "Michael A. Miller",
							office: "The Semrad Law Firm, LLC; Naperville, Ill.",
						},
						{
							name: "Ronald R. Peterson",
							office: "Jenner & Block; Chicago",
						},
						{
							name: "Keith L. Rucinski",
							office: "Office of the Chapter 13 Trustee; Akron, Ohio",
						},
						{
							name: "Russell C. Simon",
							office: "Office of the Chapter 13 Trustee; Swansea, Ill.",
						},
						{
							name: "Rachael Stokas",
							office: "Codilis & Associates, P.C.; Burr Ridge, Ill.",
						},
						{
							name: "Hon. Jamie A. Wilson",
							office: "U.S. Bankruptcy Court (S.D. Miss.); Jackson",
						},
					],
				},
			],
			days: new Set(),
			agenda: [],
			currentEvent: {
				title: "",
				description: "",
				sponsors: [],
			},
			speakers: [],
		};
		return {
			data: data,
		};
	},
	methods: {
		fetchEvents() {
			fetch("https://app.inevent.com/api/?action=activity.find&eventID=79050")
				.then((response) => response.json())
				.then((response) => {
					this.data.agenda = response.data.map((event) => {
						this.data.days.add(
							JSON.stringify({
								dayOfTheYear: getDayOfYear(fromUnixTime(event.dateBegin)),
								formatted: format(fromUnixTime(event.dateBegin), "EEEE, MMMM d"),
							})
						);
						event.day = getDayOfYear(fromUnixTime(event.dateBegin));

						return event;
					});
					console.log(this.data);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		fetchSpeakers() {
			fetch("https://app.inevent.com/api/?action=speaker.find&eventID=79050")
				.then((response) => response.json())
				.then((response) => {
					this.data.speakers = response.data;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		getTime(time) {
			return format(fromUnixTime(time), "h:mm a");
		},
		hasConcurrentTime(activity, index) {
			let matchingTimes = [];

			if (activity.date === this.data.agenda[0].date) {
				//filter first
				matchingTimes = this.data.agenda[0].events.filter((event) => {
					return event.time === activity.time && event.id !== activity.id;
				});
			} else if (activity.date === this.data.agenda[1].date) {
				//filter second
				matchingTimes = this.data.agenda[1].events.filter((event) => {
					return event.time === activity.time && event.id !== activity.id;
				});
			} else if (activity.date === this.data.agenda[2].date) {
				//filter second
				matchingTimes = this.data.agenda[2].events.filter((event) => {
					return event.time === activity.time && event.id !== activity.id;
				});
			}
			return matchingTimes.length > 0 ? true : false;
		},
		getModalContent(e) {
			this.data.currentEvent = JSON.parse(e.target.dataset.activity);
			MicroModal.show("modal-1");
		},
	},
});
app.config.globalProperties.$log = console.log;
app.mount("#app");
MicroModal.init();
